<template>
    <div
        class="h-full min-h-[136px] overflow-hidden p-2 pb-0 xs:min-h-[150px] md:min-h-[268px] lg:min-h-[200px] lg:p-0 xl:min-h-[246px] 2xl:min-h-[18vw]"
    >
        <Swiper
            id="swiperhomev3"
            :modules="[Autoplay, Pagination]"
            :loop="true"
            :autoplay="{
                delay: 5000,
                disableOnInteraction: false,
            }"
            :pagination="{
                clickable: true,
                el: `#swiper-pagination`,
            }"
            class="h-full"
        >
            <SwiperSlide
                v-for="(card, index) in bannersList.filter(
                    (banner) => banner?.enabled === true
                )"
                :key="index"
                class="relative h-auto rounded-lg"
            >
                <ThaBlocksHomeSwiperV3HomeBannerV3 :data="card" />
            </SwiperSlide>
            <div class="relative">
                <div
                    id="swiper-pagination"
                    class="absolute !left-1/2 z-10 h-7 !w-24 -translate-x-7"
                ></div>
            </div>
        </Swiper>
    </div>
</template>

<script setup>
import { Autoplay, Pagination } from 'swiper/modules'
// import { useModalStore, useUserStore, useGamesStore } from '~/stores'
// const useModalStoreInstance = useModalStore()
// const useUserStoreInstance = useUserStore()
// const useGamesStoreInstance = useGamesStore()
// const { getGameUrl } = useGamesStoreInstance
// const { showLoginModal, showPromoBtiModal } = storeToRefs(useModalStoreInstance)
// const { isLoggedIn } = storeToRefs(useUserStoreInstance)
// const { t } = useI18n({})
// the banners as components
const staticUrl = useRuntimeConfig().public.staticUrl
// const { isMobile } = useDevice()

const bannersList = computed(() => {
    return [
        {
            img: staticUrl + '/assets/img/banner/euro/banner-11-',
            img_mobile: staticUrl + '/assets/img/banner/euro/banner-11-mobile-',
            title: '',
            subTitle: '',
            titleMobile: '',
            subTitleMobile: ``,
            action: () => navigateTo('/promotions/sports-cashback'),
            class: '',
            enabled: true,
        },
        {
            // slots
            img: staticUrl + '/assets/img/banner/euro/banner-13-',
            img_mobile: staticUrl + '/assets/img/banner/euro/banner-13-mobile-',
            title: '',
            subTitle: '',
            titleMobile: '',
            subTitleMobile: ``,
            action: () => navigateTo('/promotions/slots-rebate'),
            class: '',
            enabled: true,
        },
        {
            // weekly
            img: staticUrl + '/assets/img/banner/euro/banner-9-',
            img_mobile: staticUrl + '/assets/img/banner/euro/banner-9-mobile-',
            title: '',
            subTitle: '',
            titleMobile: '',
            subTitleMobile: ``,
            action: () => navigateTo('/promotions/weekly-rebate'),
            class: '',
            enabled: true,
        },
        {
            // daily
            img: staticUrl + '/assets/img/banner/euro/banner-10-',
            img_mobile: staticUrl + '/assets/img/banner/euro/banner-10-mobile-',
            title: '',
            subTitle: '',
            titleMobile: '',
            subTitleMobile: ``,
            action: () => navigateTo('/promotions/daily-rebate'),
            class: '',
            enabled: true,
        },

        {
            // bti
            img: staticUrl + '/assets/img/banner/euro/banner-8-',
            img_mobile: staticUrl + '/assets/img/banner/euro/banner-8-mobile-',
            title: '',
            subTitle: '',
            titleMobile: '',
            subTitleMobile: ``,
            action: () => navigateTo('/promotions/bti-risk-free'),
            class: '',
            enabled: true,
        },
        // {
        //     // bti gamification
        //     img: staticUrl + '/assets/img/banner/euro/banner-7-',
        //     img_mobile: staticUrl + '/assets/img/banner/euro/banner-7-mobile-',
        //     title: '',
        //     subTitle: '',
        //     titleMobile: '',
        //     subTitleMobile: ``,
        //     action: async () => {
        //         if (isMobile) {
        //             if (isLoggedIn.value) {
        //                 const toSend = {
        //                     partnerProvider: 'bti',
        //                 }
        //                 const { data } = await getGameUrl(toSend)
        //                 if (data.value.status === 'OK') {
        //                     const { url } = data.value.data
        //                     setTimeout(() => {
        //                         window.open(url, '_blank')
        //                     })
        //                 }
        //             } else {
        //                 showLoginModal.value = true
        //             }
        //         } else {
        //             showPromoBtiModal.value = true
        //         }
        //     },
        //     class: '',
        //     enabled: true,
        // },

        // {
        //     // euro
        //     img: staticUrl + '/assets/img/banner/euro/banner-3-',
        //     img_mobile: staticUrl + '/assets/img/banner/euro/banner-3-mobile-',
        //     title: '',
        //     subTitle: '',
        //     titleMobile: '',
        //     subTitleMobile: ``,
        //     action: () => navigateTo('/promotions/8-percent'),
        //     class: '',
        // },

        // {
        //     img: staticUrl + '/assets/img/banner/euro/banner-4-',
        //     img_mobile: staticUrl + '/assets/img/banner/euro/banner-4-mobile-',
        //     title: '',
        //     subTitle: '',
        //     titleMobile: '',
        //     subTitleMobile: ``,
        //     action: async () => {
        //         if (isLoggedIn.value) {
        //             if (isMobile) {
        //                 const toSend = {
        //                     partnerProvider: 'bti',
        //                 }
        //                 const { data } = await getGameUrl(toSend)
        //                 if (data.value.status === 'OK') {
        //                     const { url } = data.value.data
        //                     setTimeout(() => {
        //                         window.open(url, '_blank')
        //                     })
        //                 }
        //             } else {
        //                 navigateTo('/btisports')
        //             }
        //         } else {
        //             showLoginModal.value = true
        //         }
        //     },
        //     class: '',
        // },
        // {
        //     // 8 percent
        //     img: staticUrl + '/assets/img/banner/euro/banner-1-',
        //     img_mobile: staticUrl + '/assets/img/banner/euro/banner-1-mobile-',
        //     title: '',
        //     subTitle: '',
        //     titleMobile: '',
        //     subTitleMobile: ``,
        //     action: () => navigateTo('/promotions/8-percent'),
        //     class: '',
        // },
        // {
        //     // welcome
        //     img: staticUrl + '/assets/img/banner/euro/banner-5-',
        //     img_mobile: staticUrl + '/assets/img/banner/euro/banner-5-mobile-',
        //     title: t('home.banner.welcome_title'),
        //     subTitle: t('home.banner.welcome_subtitle'),
        //     titleMobile: t('home.banner.welcome_title_mb'),
        //     subTitleMobile: `${t('home.banner.welcome_subtitle_mb')
        //         .split('\n')
        //         .join('<br>')}`,
        //     action: () => navigateTo('/promotions/bonus-100percent'),
        //     class: '',
        // },
        {
            // welcome
            img: staticUrl + '/assets/img/banner/euro/banner-12-',
            img_mobile: staticUrl + '/assets/img/banner/euro/banner-12-mobile-',
            title: '',
            subTitle: '',
            titleMobile: '',
            subTitleMobile: ``,
            action: () => navigateTo('/promotions/bonus-100percent'),
            class: '',
            enabled: true,
        },
        // {
        //     // choose fox
        //     img: staticUrl + '/assets/img/banner/euro/banner-2-',
        //     img_mobile: staticUrl + '/assets/img/banner/euro/banner-2-mobile-',
        //     title: '',
        //     subTitle: '',
        //     titleMobile: '',
        //     subTitleMobile: ``,
        //     action: () => navigateTo('/sports'),
        //     class: '',
        //     enabled: true,
        // },

        // {
        //     // livecasino
        //     img: staticUrl + '/assets/img/banner/euro/banner-6-',
        //     img_mobile: staticUrl + '/assets/img/banner/euro/banner-6-mobile-',
        //     title: t('home.banner.live_casino_title'),
        //     subTitle: `${t('home.banner.live_casino_subtitle')
        //         .split('\n')
        //         .join('<br>')}`,
        //     titleMobile: t('home.banner.live_casino_title'),
        //     subTitleMobile: `${t('home.banner.live_casino_subtitle')
        //         .split('\n')
        //         .join('<br>')}`,
        //     action: () => navigateTo('/casino/all?partner='),
        //     class: 'banner-default banner-default-sport banner-default-three',
        // },
    ]
})
</script>
<style lang="scss" scoped>
:deep() {
    #swiperhomev3 .swiper-pagination-bullet {
        border: none;
        background: rgba(255, 255, 255, 0.2) !important;
        margin: 0 2px !important;
        @media screen and (min-width: 1024px) {
            width: 8px;
            height: 8px;
        }
        @media screen and (max-width: 1023px) {
            width: 0.93vw;
            height: 0.93vw;
        }
    }
    #swiperhomev3 .swiper-pagination-bullet-active {
        background: rgba(255, 255, 255, 0.5) !important;
    }
}
</style>
